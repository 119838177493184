import { Box, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import Image from 'next/image';
import { ReactNode } from 'react';

import { assetsUri } from '@/services/api.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { directusImageLoader } from '@/utils/loaders';

import styles from './Overview.module.scss';

type SimpleGridComponentProps = {
  className?: string;
  children: ReactNode;
  columns?: number[];
  alignItems?: string[];
  maxW?: string[];
  width?: string[];
  marginX?: string[];
};

type StackComponentProps = {
  className?: string;
  children: ReactNode;
  paddingY?: { [key: string]: string };
  paddingX?: { [key: string]: string };
  order?: { [key: string]: string };
  alignContent?: string;
};

const SimpleGridComponent = ({
  className,
  children,
  columns = [1, 1, 1, 2],
  alignItems = ['center'],
  maxW = ['1280'],
  width = ['100%'],
  marginX = ['auto'],
}: SimpleGridComponentProps) => {
  return (
    <SimpleGrid
      className={className}
      columns={columns}
      alignItems={alignItems}
      maxW={maxW}
      width={width}
      marginX={marginX}
    >
      {children}
    </SimpleGrid>
  );
};

const StackComponent = ({
  className,
  children,
  paddingY = { base: '10', md: '24', lg: '70px' },
  paddingX = { base: '6', md: '40', lg: '70px' },
  order = undefined,
  alignContent = 'center',
}: StackComponentProps) => {
  return (
    <Stack
      className={className}
      paddingY={paddingY}
      paddingX={paddingX}
      order={order}
      alignContent={alignContent}
    >
      {children}
    </Stack>
  );
};

type ImageComponentProps = {
  src: string;
  className: string;
  width?: number;
  height?: number;
  objectFit?: string | undefined;
  alt?: string;
  loading?: 'lazy' | 'eager';
};

const ImageComponent = ({
  src,
  className,
  width = 640,
  height = 420,
  objectFit = 'cover',
  alt = '',
  loading = 'lazy',
}: ImageComponentProps) => {
  return (
    <Image
      src={src}
      loader={directusImageLoader}
      className={className}
      width={width}
      height={height}
      objectFit={objectFit}
      alt={alt}
      loading={loading}
    />
  );
};

type Props = {
  specialDetails: SpecialDetails;
};

const Overview = ({ specialDetails }: Props) => {
  return (
    <Box
      paddingTop={{ base: '0', md: '56px' }}
      paddingBottom={{ base: '0px', md: '56px' }}
      marginX={{ base: -4, md: 4 }}
      className={styles.overviewSectionWrapper}
    >
      {specialDetails &&
        specialDetails?.content_blocks?.length &&
        specialDetails?.content_blocks?.map((item, index) => {
          return (
            <SimpleGridComponent key={index} className={styles.overviewItem}>
              <Box
                className={styles.overviewImgBlock}
                order={index % 2 === 0 ? { base: 'unset', lg: '2' } : undefined}
              >
                <ImageComponent
                  src={`${assetsUri}/${item?.image || ''}`}
                  className={styles.overviewImage}
                  alt={item?.title}
                  loading={index === 0 ? 'eager' : 'lazy'}
                />
              </Box>
              <StackComponent className={styles.overviewDetails}>
                {!(index % 2 === 0) ? (
                  <Box
                    className={styles.headingElement}
                    order={{ base: '2', lg: '1' }}
                  >
                    <Heading
                      as="h4"
                      className={styles.titleMd}
                      marginBottom={{ base: 4, md: 5 }}
                    >
                      {item?.title}
                    </Heading>
                    <Text className={styles.titleText}>
                      {item?.description}
                    </Text>
                  </Box>
                ) : (
                  <Box className={styles.headingElement}>
                    <Heading
                      as="h4"
                      className={styles.titleMd}
                      marginBottom={{ base: 4, md: 5 }}
                    >
                      {item?.title}
                    </Heading>
                    <Text className={styles.titleText}>
                      {item?.description}
                    </Text>
                  </Box>
                )}
              </StackComponent>
            </SimpleGridComponent>
          );
        })}
    </Box>
  );
};

export default Overview;
