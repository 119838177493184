'use client';

import { Container } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { Suspense, useEffect } from 'react';

import Promotions from '@/app/_components/promotions/Promotions';
import { useSetFormItem } from '@/hooks/floating-cta.hook';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import type { FeaturedItem } from '@/types/home/home.types';
import { Range } from '@/types/request/request.types';

import BannerSection from './BannerSection';
import Overview from './Overview';
import Variants from './Variants';

interface Props {
  promotionsItems: FeaturedItem[];
  specialDetails: SpecialDetails;
  ranges: Range[];
  isSpecAvailable?: boolean;
  vehicleTitle?: string;
}

const PromotionsDetails = ({
  promotionsItems,
  specialDetails,
  ranges,
  vehicleTitle,
  isSpecAvailable,
}: Props) => {
  const setFormItem = useSetFormItem();

  const [isDesktop] = useMediaQuery('(min-width: 480px)');

  useEffect(() => {
    const leadType = getLeadTypeForSpecial(specialDetails);

    setFormItem({
      type: 'special',
      special: specialDetails,
      leadType: leadType,
    });
  }, [specialDetails, setFormItem, isDesktop]);

  return (
    <>
      {specialDetails ? (
        <>
          <BannerSection
            specialDetails={specialDetails}
            imageKeys={{
              desktopKey: 'image-1440-680',
              mobileKey: 'image-430-305',
            }}
          />
          <Suspense fallback={null}>
            <Container maxW={'1280'} width={'100%'}>
              {specialDetails && specialDetails?.content_blocks?.length && (
                <Overview specialDetails={specialDetails} />
              )}
              <Variants
                isSpecAvailable={isSpecAvailable}
                vehicleTitle={vehicleTitle}
                specialDetails={specialDetails}
              />
            </Container>
          </Suspense>
        </>
      ) : (
        <Container maxW={'1280'} width={'100%'}>
          <Box>Promotion not found</Box>
        </Container>
      )}

      <Suspense fallback={null}>
        <Promotions
          isShowNavigation
          featuredItems={promotionsItems}
          sectionTitle="Recommended Promotions"
          ranges={ranges}
        />
      </Suspense>
    </>
  );
};

export default PromotionsDetails;
