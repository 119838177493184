import { Box, Heading, Text } from '@chakra-ui/layout';
import slugify from 'react-slugify';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { SpecialDetails } from '@/services/SpecialsService.service';

import styles from './Variants.module.scss';

type Props = {
  specialDetails?: SpecialDetails;
  isSpecAvailable?: boolean;
  vehicleTitle?: string;
};
const Variants = ({ specialDetails, isSpecAvailable, vehicleTitle }: Props) => {
  return (
    <Box
      paddingY={{ base: '10', md: '24', lg: '80px' }}
      paddingX={{ base: '6', md: '40', lg: '60px' }}
      className={styles.variantWrapper}
    >
      <Box
        className={styles.headingElement}
        marginBottom={{ base: '32px', md: '44px' }}
      >
        {specialDetails?.sub_title && (
          <Text fontSize={'xs'} marginBottom={2} textTransform={'uppercase'}>
            {specialDetails.sub_title}
          </Text>
        )}

        {specialDetails?.title && (
          <Heading as="h2" className={styles.titleLg} marginBottom={2}>
            {specialDetails.title}
          </Heading>
        )}
        {isSpecAvailable && vehicleTitle && (
          <LinkCustom
            className="btnTextLink"
            href={`/new-cars/${slugify(vehicleTitle)}/specs/${specialDetails?.mmcode}`}
          >
            View Specs
          </LinkCustom>
        )}
      </Box>
      <Box
        dangerouslySetInnerHTML={{
          __html: specialDetails?.description
            ? specialDetails?.description
            : '',
        }}
        className={styles.variantTypeContainer}
      ></Box>
    </Box>
  );
};

export default Variants;
